<template>
<modal wide large title=" ">
    <columns>
        <column class="is-narrow">
            <img ref="image" class="image-viewer" :src="image.image_url"/>    
        </column>
        <column>
            <p class="has-text-weight-bold has-text-primary">Notes</p>
            <p v-if="image.notes">{{ image.notes | nl2br }}</p>
            <p v-else>Notes have not been provided</p>
        </column>
    </columns>
</modal>    
</template>
<script>
export default {
    
    props: {
        image: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        isPortrait: true,
    }),

    mounted() {
        this.isPortrait = this.$refs.image.width < this.$refs.image.height
    }

}
</script>