<template>
<modal class="full-height" title="Select Checklist Templates">

    <form class="mb-4">
        <text-input 
            v-model="search" 
            classes="has-addons is-rounded"
            placeholder="Search for Checklist Templates">
        </text-input>
    </form>

    <div class="index-rows">
        <div v-for="checklist in availableChecklists" :key="checklist.id">
            <columns>
                <column>
                    <p>{{ checklist.name }}</p>
                    <p class="has-text-grey" v-if="checklist.classification">
                        <em>{{ checklist.classification.name }}</em>
                    </p>
                </column>
                <column class="is-narrow">
                    <action-button @click="addChecklist(checklist)" class="is-small is-info is-rounded">
                        <icon icon="plus"/>
                    </action-button>
                </column>
            </columns>
        </div>
    </div>

</modal>    
</template>
<script>
import differenceBy from 'lodash/differenceBy'
import { mapGetters } from 'vuex'

export default {
    props: {
        classification: {
            type: Object,
            default: () => ({})
        },
        checklists: {
            type: Array,
            default: () => ([])
        }
    },

    data: () => ({
        search: ''
    }),

    methods: {
        addChecklist(checklist) {
            this.$store.commit('equipmentClassification/addChecklist', checklist)
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification'
        ]),
        availableChecklists() {
            let checklists = differenceBy(this.checklists, this.equipment_classification.checklists, 'id')

            if (this.search.length) {
                return checklists.filter(checkpoint => {
                    return checkpoint.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                })
            }

            return checklists
        }
    }
}
</script>