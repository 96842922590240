<template>
<modal title="Column Name">
    <text-input
        v-model="name"
    />

    <template #footer>
        <submit-button @click="save" class="is-rounded">Save</submit-button>
    </template>
</modal>    
</template>
<script>
export default {
    props: {
        column: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        name: ''
    }),

    created() {
        this.name = this.column.label
    },

    methods: {
        save() {
            this.$close(this.name)
        }
    }
}
</script>