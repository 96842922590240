<template>
<modal wide title="New Checkpoint">
    <form>
        <columns>
            <column>
                <columns>
                    <column class="is-7">
                        <text-input classes="is-rounded" :error="$root.errors.instruction" @input="updateInstruction"
                            :value="checkpoint.instruction"
                            placeholder="Eg. Take a reading of the current mileage of the odometer"
                            description="The main instruction to complete on the checkpoint" required>
                            Instruction
                        </text-input>
                    </column>
                    <column>
                        <data-selector classes="is-rounded"
                            description="The type determines what sort of tools are made available to the user"
                            :error="$root.errors.checkpoint_type_id" @input="updateType" :items="checkpoint_types"
                            :value="checkpoint.checkpoint_type_id" value-key="id" label-key="name" required>
                            Type
                        </data-selector>
                    </column>
                </columns>
                <columns>
                    <column>
                        <text-input classes="is-rounded" :error="$root.errors.additional_information"
                            @input="updateAdditionalInformation" :value="checkpoint.additional_information"
                            description="Any addtional information to aid the team member in completing the instruction">
                            Additional Information <small class="has-text-weight-light">(optional)</small>
                        </text-input>
                    </column>
                    <column class="is-4">
                        <switch-input classes="is-rounded" :error="$root.errors.point_of_failure"
                            @input="togglePointOfFailure" :value="checkpoint.point_of_failure" :items="failure_options"
                            hint="Mark this checkpoint as a critical checkpoint">
                            Point of failure <small class="has-text-weight-light">(optional)</small>
                        </switch-input>
                    </column>
                </columns>
                <columns>
                    <column>
                        <select-input required no-revert classes="is-rounded"
                            description="This setting determines how notes are handled when completing a checklist"
                            :error="$root.errors.comment_configuration" :items="comment_configurations"
                            :value="checkpoint.comment_configuration" @input="updateCommentConfiguration">
                            Note Configuration
                        </select-input>
                    </column>
                    <column>
                        <select-input classes="is-rounded"
                            description="This setting determines how media is handled when completing a checklist" required
                            no-revert :error="$root.errors.image_configuration" :items="image_configurations"
                            :value="checkpoint.image_configuration" @input="updateImageConfiguration">
                            Media Configuration
                        </select-input>
                    </column>
                </columns>
    
                <checkpoint-type-builder v-if="checkpoint.checkpoint_type_id" />
            </column>
        </columns>
    </form>
    <template #footer>
        <div class="buttons has-addons is-rounded">
            <action-button @click="$close(false)" class="is-rounded is-danger">Cancel</action-button>
            <action-button @click="createCheckpoint" class="is-rounded is-primary">Save</action-button>
        </div>
    </template>
</modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import CheckpointTypeBuilder from '@/pages/checkpoint/builder/Builder'
import { checklist as backend } from '@/api'

export default {

    components: {
        CheckpointTypeBuilder
    },

    data: () => ({
        loading: true,
        creating: false,
        checklists: [],
        failure_options: [
            {
                value: false,
                label: 'No'
            },
            {
                value: true,
                label: 'Yes'
            }
        ]
    }),

    beforeDestroy() {
        this.clearCheckpoint()
    },

    async beforeCreate() {
        await this.$store.dispatch('checkpoint/loadTypes')
        await backend.loadList(null, ({ data }) => this.checklists = data, () => { })
        this.loading = false
    },

    methods: {
        ...mapMutations('checkpoint', [
            'updateInstruction',
            'clearCheckpoint',
            'updateType',
            'updateCommentConfiguration',
            'updateImageConfiguration',
            'updateAdditionalInformation',
            'updateChecklists',
            'togglePointOfFailure',
            'setHandlerPayload'
        ]),
        createCheckpoint() {
            this.creating = true
            this.$store.dispatch('checkpoint/create').then((data) => {
                this.$toast.success('Checkpoint successfully created')
                this.creating = false
                this.$store.commit('checklist/addCheckpoint', data)
                const checkpoint_type_id = this.checkpoint.checkpoint_type_id;
                this.clearCheckpoint()
                this.updateType(checkpoint_type_id)
                this.setHandlerPayload({
                    ok_label: 'Ok',
                    ok_value: 1,
                    failure_label: 'Faulty',
                    failure_value: 0,
                    na_label: 'N/A',
                    na_value: -1,
                    with_na: false
                })
                this.$close(false)
            }).catch(() => this.creating = false)
        }
    },

    computed: mapGetters('checkpoint', [
        'checkpoint',
        'checkpoint_types',
        'comment_configurations',
        'image_configurations',
    ])

}
</script>