<template>
<modal>
  <div id="card-form"></div>
</modal>
</template>
<script>
import PeachPayments from '../../../utils/peach-payments'

export default {

  props: {
    checkout: {
      type: Object,
      default: () => ({})
    }
  },

  mounted() {
    const peachPayments = new PeachPayments(this.checkout)
    peachPayments.initCard()
    setTimeout(() => {
      peachPayments.initForm('card-form')
    }, 100)
  }

}
</script>