<template>
    <modal wide title="Update Attachment">
        <columns>
            <column>
                <text-input v-model="updatable.name">
                    Attachment Name
                </text-input>
            </column>
        </columns>
        <columns>
            <column>
                <file-input @select="setAttachment">
                    Attachment
                </file-input>
            </column>
        </columns>

        <template #footer>
            <columns>
                <column class="is-flex is-align-items-center">
                    <action-button class="is-danger is-small is-rounded" :working="deleting" @click="deleteAttachment">
                        Delete
                    </action-button>
                </column>
                <column>
                    <action-button class="is-rounded" :working="uploading" @click="updateAttachment">
                        Update
                    </action-button>
                </column>
            </columns>
        </template>
    </modal>
</template>
<script>
import { attachment as backend } from '@/api'

export default {

    props: {
        attachment: Object,
    },

    data: () => ({
        uploading: false,
        deleting: false,
        updatable: {
            name: '',
            attachable_id: '',
            attachable_type: '',
            attachment: null
        }
    }),

    created() {
        this.updatable = {...this.attachment}
    },

    methods: {
        setAttachment(file) {
            this.updatable.attachment = file
        },
        async deleteAttachment() {
            if(await this.$confirm({
                title: 'Delete Attachment',
                message: 'Are you sure you want to delete this attachment?'
            })) {
                this.deleting = true
                backend.deleteAttachment(this.attachment.id, () => {
                    this.$toast.success('Updated')
                    this.$close({
                        event: 'deleted',
                        file: this.attachment
                    })
                    this.deleting = false
                }, () => {
                    this.$toast.error('Unable to upload your attachment, please try again.')
                    this.deleting = false
                })
            }
        },
        updateAttachment() {
            this.uploading = true
            backend.updateAttachment(this.updatable, ({ data }) => {
                this.$toast.success('Updated')
                this.$close({
                    event: 'updated',
                    file: data
                })
                this.uploading = false
            }, () => {
                this.$toast.error('Unable to upload your attachment, please try again.')
                this.uploading = false
            })
        }
    }

}
</script>