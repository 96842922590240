<template>
<modal wide title="New Attachment">
    <columns>
        <column>
            <text-input 
                v-model="attachment.name">
                Attachment Name
            </text-input>
        </column>
    </columns>
    <columns>
        <column>
            <file-input @select="setAttachment">
                Attachment
            </file-input>
        </column>
    </columns>

    <template #footer>
        <action-button 
            class="is-rounded"
            :working="uploading"
            @click="uploadAttachment">
            Upload
        </action-button>
    </template>
</modal>
</template>
<script>
import { attachment as backend } from '@/api'

export default {

    props: {
        attachableType: String,
        attachableId: Number
    },

    data: () => ({
        uploading: false,
        attachment: {
            name: '',
            attachable_id: '',
            attachable_type: '',
            attachment: null
        }
    }),

    methods: {
        setAttachment(file) {
            this.attachment.attachment = file
        },
        uploadAttachment() {
            this.uploading = true
            this.attachment.attachable_type = this.attachableType,
            this.attachment.attachable_id = this.attachableId,
            backend.uploadAttachment(this.attachment, ({data}) => {
                this.$toast.success('Uploaded')
                this.$close(data)
                this.uploading = false
            }, () => {
                this.$toast.error('Unable to upload your attachment, please try again.')
                this.uploading = false
            })
        }
    }

}
</script>