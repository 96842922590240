<template>
<div>
    <columns>
        <column>
            <h5 class="title is-4">
                Linked Equipment Classifications <small class="has-text-weight-light">(optional)</small>
            </h5>    
            <h6 class="subtitle is-6 has-text-grey">
                If you choose not to assign any Equipment Classifications to this template <em>all unclassified</em> Equipment Classifications will be made available to it by default. 
                However, once you assign Classifications to a checklist template, those become the only templates available to it.
            </h6>
        </column>
        <column class="is-4 is-flex is-justify-content-end">
            <div class="buttons">
                <action-button @click="openNewClassification" class="is-ghost is-small" left-icon="plus">Create Equipment Classification</action-button>
                <action-button @click="openLibrary" class="is-small is-warning is-rounded" left-icon="search">Search Classification Library</action-button>
            </div>
        </column>
    </columns>
    <columns>
        <column>
            <div class="index-rows">
                <equipment-classification-row 
                    class="box is-marginless"
                    v-for="classification in checklist.equipment_classifications" :key="classification.id"
                    :classification="classification"
                />
            </div>

            <p v-if="!checklist.equipment_classifications.length" class="pt-3 has-text-centered">
                There are no Equipment Classifications linked to this Checklist Template.
            </p>    
        </column>
    </columns>
</div>
</template>
<script>
import EquipmentClassificationRow from './EquipmentClassificationRow.vue'
import { mapGetters } from 'vuex'
import { addExistingEquipmentClassifications, quickEquipmentClassification } from '@/modals'

export default {

    components: {
        EquipmentClassificationRow
    },

    beforeCreate() {
        this.$store.dispatch('equipmentClassification/loadList')
    },
    
    methods: {
        addClassification(classification) {
            this.$store.commit('checklist/addEquipmentClassification', classification)
        },
        openLibrary() {
            addExistingEquipmentClassifications(this.equipment_classification_list)
        },
        openNewClassification() {
            quickEquipmentClassification().then(classification => {
                if(classification) {
                    this.addClassification(classification)
                }
            })
        }
    },

    computed: {
        ...mapGetters('checklist', [
            'checklist'
        ]),
        ...mapGetters('equipmentClassification', [
            'equipment_classification_list'
        ])
    }

}
</script>