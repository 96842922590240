<template>
<div>
  <component 
    v-if="checkpointType"
    :is="checkpointType.builder + '-builder'"
    :builder-payload="checkpointType.builder_payload"
  />
</div>  
</template>
<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'
export default {
  
  computed: {
    ...mapGetters('checkpoint', [
      'checkpoint',
      'checkpoint_types'
    ]),
    checkpointType() {
      return find(this.checkpoint_types, type => type.id === this.checkpoint.checkpoint_type_id)
    }
  },

}
</script>