<template>
<columns class=" is-marginless is-paddingless">
    <column class="is-flex is-align-items-center">
        {{ classification.name }}
    </column>
    <column class="is-narrow">
        <number-input :precision="0" :value="classification.pivot.interval" @input="updateChecklistInterval"
            classes="is-small is-rounded" placeholder="In days"
            hint="If required you may set a regular interval at which this checklist should be carried out. Set in days">
            Repeat Interval
        </number-input>
    </column>
    <column class="is-1 is-flex is-align-items-center is-justify-content-end">
        <action-button class="is-small is-danger is-rounded" @click="removeClassification(classification)">
            <icon icon="trash"/>
        </action-button>
    </column>
</columns>
</template>
<script>
export default {
    props: {
        classification: {
            type: Object,
            default: () => ({})
        }
    },

    methods: {
        removeClassification(classification) {
            this.$store.commit('checklist/removeEquipmentClassification', classification)
        },
        updateChecklistInterval(interval) {
            this.$store.commit('checklist/updateChecklistInterval', {
                classification: this.classification,
                interval
            })
        }
    }
}
</script>