<template>
  <modal v-bind="{ title }">
    <article class="content" v-html="message"/>
    <div slot="footer" class="buttons">
      <action-button :class="['is-rounded', cancelClass]" @click="$close(false)">{{ cancelText }}</action-button>
      <action-button :class="['has-text-weight-bold is-rounded', confirmClass]" @click="$close(true)">{{ confirmText }}</action-button>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmClass: {
      type: String,
      default: 'is-success'
    },
    cancelClass: {
      type: String,
      default: 'is-light'
    }
  }
}
</script>
