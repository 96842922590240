<template>
<div>
    <columns>
        <column>
            <h4 class="title is-5">Permissions</h4>
        </column>
        <transition name="fade">
            <column class="is-narrow" v-if="authIsSuper && user.id && !user.is_passive">
                <action-button
                    v-if="!isAnAdmin"
                    :disabled="authUser.id === user.id"
                    @click="grantAdminAccess"
                    class="is-small is-rounded is-warning">
                    Grant Admin Access
                </action-button>
                <action-button
                    v-if="isAnAdmin"
                    :disabled="authUser.id === user.id"
                    @click="revokeAdminAccess"
                    class="is-small is-rounded is-warning">
                    Revoke Admin Access
                </action-button>
            </column>
        </transition>
    </columns>
    <div class="index-rows">
        <div @click="togglePermission(permission)" class="box is-hoverable is-marginless is-flex" v-for="permission in permissions" :key="permission.id">
            <icon 
                :icon="user.abilities.map(permission => permission.id).includes(permission.id) || isSuperUser ? 'check-circle' : 'circle'" 
                type="far" 
                class="mr-2"
                :class="{
                    'has-text-success': user.abilities.map(permission => permission.id).includes(permission.id) || isSuperUser
                }"
            />
            {{ permission.title }}
        </div>
    </div>
</div>    
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    
    props: {
        permissions: {
            type: Array,
            default: () => ([])
        },
        isSuperUser: Boolean
    },

    methods: {
        togglePermission(permission) {
            if(!this.isSuperUser) {
                this.$store.commit('user/togglePermission', permission)
            }
        },
        async grantAdminAccess() {
            if(await this.$confirm({
                title: 'Grant Admin Access',
                message: 'This user will gain access to all areas of your account and all data in your account.'
            })) {
                this.$store.dispatch('user/grantAdminAccess').then(() => {

                }).catch(() => { })
            }
        },
        revokeAdminAccess() {
            this.$store.dispatch('user/revokeAdminAccess').then(() => {

            }).catch(() => {})
        }
    },

    computed: {
        ...mapGetters('user', [
            'user'
        ]),
        authUser() {
            return this.$store.getters['auth/user']
        },
        isAnAdmin() {
            return this.user.abilities.filter(permission => permission.name === '*').length
        },
        ...mapGetters('billing', [
            'subscription'
        ]),
        authIsSuper() {
            return this.subscription.account.user_id === this.authUser.id
        },
    }

}
</script>