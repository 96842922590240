<template>
<modal title="Transfer Work Order & Equipment">
    <columns>
        <column>
            <p class="has-text-danger">Transferring Work Orders between locations will also transfer checklists performed and any equipment
                related with the work orders.
            </p>
        </column>
    </columns>
    <columns>
        <column>
            <data-selector
                classes="is-rounded has-addons"
                required
                :items="availableLocations"
                v-model="to_location_uuid"
                value-key="uuid"
                label-key="name">
                Location
            </data-selector>
        </column>
    </columns>
    <columns>
        <column>
            <checkbox-input
                v-model="acknowledged">
                I acknowledge that this process will transfer all work orders, equipment and checklists and cannot be undone.
            </checkbox-input>    
        </column>
    </columns>
    <columns>
        <column>
            <action-button
                :disabled="!acknowledged"
                :working="transferring"
                @click="transfer"
                class="is-rounded"
                left-icon="rotate">
                Transfer
            </action-button>    
        </column>
    </columns>
</modal>    
</template>
<script>
import { customer as backend } from '@/api'
import { location as locationBackend } from '@/api'

export default {

    props: {
        location: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        transferring: false,
        locations: [],
        to_location_uuid: '',
        acknowledged: false
    }),

    created() {
        backend.loadCustomerLocationsList(this.location.customer_uuid, ({data}) => {
            this.locations = data
        }, () => {})
    },

    methods: {
        async transfer() {
            if(await this.$confirm({
                title: "Transfer Work Orders, Checklists and Equipment",
                message: "Are you sure you want to action this transfer?"
            })) {
                this.transferring = true
                locationBackend.transferWorkOrders({
                    from_location_uuid: this.location.uuid,
                    to_location_uuid: this.to_location_uuid
                }, () => {
                    this.$close(this.to_location_uuid)
                }, this.transferring = false)
            }
        }
    },

    computed: {
        availableLocations() {
            return this.locations.filter(location => location.uuid !== this.location.uuid)
        }
    }

}
</script>