<template>
    <modal title="Equipment Classification Library">
        <columns>
            <column>
                <form>
                    <text-input v-model="search" placeholder="Filter existing classifications" classes="has-addons is-rounded">
                    </text-input>
                </form>
            </column>
        </columns>
        <div class="index-rows">
            <div v-for="classification in availableClassifications" :key="classification.id">
                <columns>
                    <column>
                        <p>{{ classification.name }}</p>
                    </column>
                    <column class="is-narrow">
                        <action-button @click="addClassification(classification)" class="is-small is-info is-rounded">
                            <icon icon="plus" />
                        </action-button>
                    </column>
                </columns>
            </div>
        </div>
    </modal>
</template>
<script>
import { mapGetters } from 'vuex'
import differenceBy from 'lodash/differenceBy'

export default {

    props: {
        classifications: Array,
        default: () => ([])
    },

    data: () => ({
        search: ''
    }),

    methods: {
        addClassification(classification) {
            this.$store.commit('checklist/addEquipmentClassification', classification)
        }
    },

    computed: {
        ...mapGetters('checklist', ['checklist']),
        availableClassifications() {
            let classifications = differenceBy(this.classifications, this.checklist.equipment_classifications, 'id')

            if (this.search.length) {
                return classifications.filter(classification => {
                    return classification.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                })
            }

            return classifications
        }
    }

}
</script>