<template>
<modal title="Checkpoint Library">
    <columns>
        <column>
            <form>
                <text-input 
                    v-model="search"
                    placeholder="Filter existing checkpoints"
                    classes="has-addons is-rounded">
                </text-input>
            </form>
        </column>
    </columns>
    <div class="index-rows">
        <div v-for="checkpoint in availableCheckpoints" :key="checkpoint.id">
            <columns>
                <column>
                    <p>{{ checkpoint.instruction }}</p>
                    <p class="has-text-grey">
                        <em>{{ checkpoint.additional_information ?? ' ' }}</em>
                    </p>
                </column>
                <column class="is-narrow">
                    <action-button @click="addCheckpoint(checkpoint)" class="is-small is-info is-rounded">
                        <icon icon="plus"/>
                    </action-button>
                </column>
            </columns>
        </div>
    </div>
</modal>
</template>
<script>
import { mapGetters } from 'vuex'
import differenceBy from 'lodash/differenceBy'

export default {

    props: {
        checkpoints: Array,
        default: () => ([])
    },

    data: () => ({
        search: ''
    }),

    methods: {
        addCheckpoint(checkpoint) {
            this.$store.commit('checklist/addCheckpoint', checkpoint)
        }
    },

    computed: {
        ...mapGetters('checklist', ['checklist']),
        availableCheckpoints() {
            let checkpoints =  differenceBy(this.checkpoints, this.checklist.checkpoints, 'id')

            if(this.search.length) {
                return checkpoints.filter(checkpoint => {
                    return checkpoint.instruction.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                })
            }

            return checkpoints
        }
    }

}
</script>