<template>
    <modal wide title="New Equipment Classification">
        <form>
            <columns>
                <column>
                    <text-input classes="is-medium is-rounded" placeholder="Eg. Delivery Van" required
                        :error="$root.errors.name" :value="equipment_classification.name" @input="updateName">
                        Name
                    </text-input>
                </column>
            </columns>
            <meta-data-builder />
        </form>

        <template #footer>
            <div class="buttons">
                <action-button class="is-ghost is-small has-text-danger" @click="$close(false)">
                    Cancel
                </action-button>
                <submit-button class="is-rounded is-medium mt-3" :working="working" @submit="createClassification">
                    Save
                </submit-button>
            </div>
        </template>
    </modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import MetaDataBuilder from '../pages/equipment-classification/partials/MetaDataBuilder.vue'

export default {
    components: {
        MetaDataBuilder,
    },

    data: () => ({
        loading: false,
        working: false,
    }),

    beforeDestroy() {
        this.clearEquipmentClassification()
    },

    methods: {
        ...mapMutations('equipmentClassification', [
            'updateName',
            'clearEquipmentClassification'
        ]),
        createClassification() {
            this.working = true
            this.$store.dispatch('equipmentClassification/create').then((data) => {
                this.$toast.success('Classification created')
                this.$close(data)
                this.working = false
            }).catch(() => this.working = false)
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification'
        ])
    }

}
</script>