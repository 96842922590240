<template>
<modal title="Select a Template">
    <loader v-bind="{ loading }">
        <columns>
            <column>
                <select-input classes="is-medium is-rounded" :items="templates" v-model="template" value-key="id" label-key="name">
                    Available Templates
                </select-input>
            </column>
        </columns>
    </loader>
    <template #footer>
        <action-button @click="selectTemplate">Select Template</action-button>
    </template>
</modal>
</template>
<script>
import { workOrderTemplate as backend } from '@/api'
import find from 'lodash/find'

export default {

    data: () => ({
        loading: true,
        templates: [],
        template: ''
    }),

    beforeCreate() {
        backend.loadList(({data}) => {
            this.templates = data
            this.loading = false
        }, () => this.loading = false)
    },

    methods: {
        selectTemplate() {
            if(!this.template) {
                return this.$toast.error('Please select a template')
            }

            this.$close(
                find(this.templates, template => template.id === this.template)
            )
        }
    }

}
</script>