<template>
<modal wide title="New User">

    <loader v-bind="{ loading }">
        <form>
            <columns>
            <column>
                <text-input
                    classes="is-medium is-rounded"
                    :value="user.name"
                    @input="updateName"
                    :error="$root.errors.name"
                    required>
                    Name
                </text-input>
                </column>
                <column>
                <text-input
                    classes="is-medium is-rounded"
                    :value="user.surname"
                    @input="updateSurname"
                    :error="$root.errors.surname"
                    required>
                    Last Name
                </text-input>
                </column>
            </columns>
            <columns>
                <column>
                <text-input
                    classes="is-medium is-rounded"
                    :value="user.email"
                    @input="updateEmail"
                    :error="$root.errors.email"
                    required>Email Address
                </text-input>
                </column>
                <column>
                <text-input
                    classes="is-medium is-rounded"
                    :value="user.profile.mobile"
                    @input="updateMobile"
                    :error="$root.errors.mobile">
                    Mobile Number <span class="has-text-weight-light">(optional)</span>
                </text-input>
                </column>
                <column>
                <text-input
                    classes="is-medium is-rounded"
                    :value="user.designation"
                    @input="updateDesignation"
                    :error="$root.errors.designation">
                    Designation <span class="has-text-weight-light">(optional)</span>
                </text-input>
                </column>
            </columns>

            <columns>
                <column>
                    <permissions-builder :permissions="permissions"/>
                </column>
            </columns>
        </form>
    </loader>

    <template #footer>
        <columns>
            <column>
                <action-button
                    @click="$close(false)"
                    class="is-small is-ghost has-text-danger">
                    Cancel
                </action-button>
                <action-button
                    :working="creating"
                    @click="createUser"
                    class="is-small is-rounded is-info">
                    Create User
                </action-button>
            </column>
        </columns>
    </template>
</modal>    
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { team as backend } from '@/api'
import { permissions as permissionBackend } from '@/api'
import PermissionsBuilder from '../../user/partials/PermissionsBuilder.vue'

export default {

    props: {
        branch: {
            type: Object,
            default: () => ({})
        }
    },

    components: {
        PermissionsBuilder
    },

    data: () => ({
        loading: true,
        creating: false,
        teams: [],
        permissions: []
    }),

    async beforeCreate() {
        await permissionBackend.loadPermissions(({ data }) => {
            this.updateBranches([this.branch])
            this.permissions = data
        }, () => { })
        this.loading = false
    },

    created() {
        this.clearUser()
    },

    beforeDestroy() {
        this.clearUser()
    },

    methods: {
        ...mapMutations('user', [
            'updateName',
            'updateSurname',
            'updateEmail',
            'updateMobile',
            'updateBranches',
            'clearUser',
            'updateTeams',
            'updateDesignation'
        ]),
        createUser() {
            this.creating = true
            this.$store.dispatch('user/createUser').then((user) => {
                this.$toast.success('User successfully created')
                this.creating = false
                this.$close(user)
            }).catch(() => this.creating = false)
        },
        loadTeamsForBranches() {
            if (this.user.branches.length) {
                backend.loadTeamList({
                    branch_ids: this.user.branches.map(branch => branch.id)
                }, ({ data }) => {
                    this.teams = data
                }, () => { })
            } else {
                this.teams = []
                this.updateTeams([])
            }
        }
    },

    computed: {
        ...mapGetters('user', [
            'user'
        ]),
        ...mapGetters('branch', [
            'branch_list'
        ]),
    },

    watch: {
        'user.branches': 'loadTeamsForBranches'
    }


}
</script>