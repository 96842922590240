<template>
    <modal title="Select a Template">
        <loader v-bind="{ loading }">
            <div v-if="!next_schedule || nextScheduleIsPast">
                <date-picker
                    v-model="next_run">
                    Next Run 
                </date-picker>
            </div>
            <div class="content" v-else>
                <p>This Schedule may be resumed without any further intervention.</p>
                <p>The next scheduled run is for {{ next_schedule.scheduled_for | date }}</p>
            </div>
        </loader>
        <template #footer>
            <action-button @click="resumeSchedule">Resume Schedule</action-button>
        </template>
    </modal>
</template>
<script>
import { workOrderTemplate as backend } from '@/api'
import moment from 'moment'

export default {

    props: {
        templateId: Number
    },

    data: () => ({
        loading: true,
        next_schedule: null,
        next_run: ''
    }),

    created() {
        backend.loadNextSchedule(this.templateId, ({ data }) => {
            this.next_schedule = data
            this.loading = false
        }, () => this.loading = false)
    },

    methods: {
        resumeSchedule() {
            if(this.nextScheduleIsPast && !this.next_run) {
                return this.$toast.error('Please select a date in the future for the next run.')
            }

            this.$store.dispatch('workOrderTemplate/resume', {
                next_run: this.next_run
            }).then(() => {
                this.$close(true)
            })
        }
    },
    
    computed: {
        nextScheduleIsPast() {
            if(!this.next_schedule) {
                return true
            }

            return moment(this.scheduled_for).isBefore(moment())
        },
    }

}
</script>