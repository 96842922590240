<template>
    <modal wide title="New Checklist Template">
        <form>
            <columns>
                <column>
                    <text-input classes="is-rounded" :value="checklist.name" @input="updateName" :error="$root.errors.name"
                        required description="A descriptive name for this template">
                        Name
                    </text-input>
                </column>
                <column>
                    <number-input classes="is-rounded" :precision="0" :max="100" :min="0" :value="checklist.pass_percentage"
                        @input="updatePassPercentage" :error="$root.errors.pass_percentage"
                        description="If left as 0 this template will not be assessed for a pass or failure">
                        Pass Percentage
                    </number-input>
                </column>
                <column>
                    <data-selector classes="is-rounded" :items="classifications" value-key="uuid" label-key="name"
                        :value="checklist.classification_uuid" @input="updateClassification"
                        :error="$root.errors.classification_uuid" required description="Categorise this template">
                        Classification

                        <template #right>
                            <action-button @click="openQuickClassification" class="is-rounded">
                                <icon icon="plus" />
                            </action-button>
                        </template>
                    </data-selector>
                </column>
            </columns>
            <columns>
                <column class="is-4">
                    <time-input classes="is-rounded" required :value="checklist.estimated_duration"
                        @input="updateEstimatedDuration" :error="$root.errors.estimated_duration"
                        description="Estimated durations are used to compare real world durations on checklists vs expected time."
                        hint="Format: 1h30m which translates to 1 hour 30 minutes">
                        Estimated Duration of Checklist
                    </time-input>
                </column>
                <column>
                    <text-area classes="is-rounded" @input="updateDescription" :value="checklist.description">
                        Description
                    </text-area>
                </column>
            </columns>

            <columns>
                <column>
                    <checkpoint-builder />
                </column>
            </columns>

            <columns>
                <column>
                    <equipment-classification-builder />
                </column>
            </columns>

            <columns>
                <column>
                    <submit-button class="is-rounded" :working="creating" @submit="createChecklist">
                        Save Template
                    </submit-button>
                </column>
            </columns>
        </form>
    </modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { checklistClassification as backend } from '@/api'
import EquipmentClassificationBuilder from '../partials/EquipmentClassificationBuilder.vue'
import CheckpointBuilder from '../partials/CheckpointBuilder.vue'
import { quickChecklistClassification } from '@/modals'

export default {

    components: {
        EquipmentClassificationBuilder,
        CheckpointBuilder
    },

    data: () => ({
        loading: false,
        creating: false,
        classifications: []
    }),

    async beforeCreate() {
        await backend.loadList(({ data }) => {
            this.classifications = data
        })
        this.loading = false
    },

    beforeDestroy() {
        this.clearChecklist()
    },

    methods: {
        ...mapMutations('checklist', [
            'updateName',
            'updatePassPercentage',
            'updateDescription',
            'clearChecklist',
            'updateClassification',
            'updateEstimatedDuration'
        ]),
        createChecklist() {
            this.creating = true
            this.$store.dispatch('checklist/create').then(checklist => {
                this.creating = false
                this.$toast.success('Checklist Successfully Created')
                this.$close(checklist)
            }).catch(() => this.creating = false)
        },
        openQuickClassification() {
            quickChecklistClassification().then(classification => {
                if (classification) {
                    this.classifications.push(classification)
                    this.updateClassification(classification.id)
                }
            })
        }
    },

    computed: {
        ...mapGetters('checklist', [
            'checklist'
        ])
    }

}
</script>