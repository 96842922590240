import Vue from 'vue'
import { create } from 'vue-modal-dialogs'

import Alert from './common/Alert'
import Confirm from './common/Confirm'
Vue.prototype.$alert = create(Alert, 'title', 'message')
Vue.prototype.$confirm = create(Confirm)

import AddCard from '@/pages/account/modals/AddCard.vue'
import PhotoEvidence from './PhotoEvidence.vue'
import RenameColumnName from '@/pages/reporting/builder/ColumnNameInput.vue'
import QuickEquipmentClassification from './QuickEquipmentClassification.vue'
import QuickCheckpoint from './QuickCheckpoint.vue'
import SelectWorkOrderTemplate from './SelectWorkOrderTemplate.vue'
import ResumeWorkOrderSchedule from './ResumeWorkOrderSchedule.vue'
import CreateTeamUser from '@/pages/team/modals/CreateUser.vue'
import CreateTeamBranch from '@/pages/team/modals/CreateBranch.vue'
import AddExistingCheckpoints from '@/pages/checklist-template/modals/AddExistingCheckpoints.vue'
import AddExistingEquipmentClassifications from '@/pages/checklist-template/modals/AddExistingEquipmentClassifications.vue'
import QuickChecklistClassification from './QuickChecklistClassification.vue'
import ImportEquipment from '@/pages/location/modals/ImportEquipment.vue'
import SelectChecklists from '@/pages/equipment-classification/modals/SelectChecklists.vue'
import QuickChecklistTemplate from '@/pages/checklist-template/modals/QuickChecklistTemplate.vue'
import UploadAttachment from '@/pages/attachment/Upload.vue'
import UpdateAttachment from '@/pages/attachment/Edit.vue'
import ChangeScheduleDate from '@/pages/work-order-template/modals/ChangeScheduleDate.vue'
import TransferLocationWorkOrders from '@/pages/location/modals/TransferWorkOrders.vue'

export const addCard = create(AddCard, 'checkout')
export const photoEvidence = create(PhotoEvidence, 'image')
export const renameColumnName = create(RenameColumnName, 'column')
export const quickCheckpoint = create(QuickCheckpoint)
export const quickEquipmentClassification = create(QuickEquipmentClassification)
export const selectWorkOrderTemplate = create(SelectWorkOrderTemplate)
export const resumeWorkOrderSchedule = create(ResumeWorkOrderSchedule, 'templateId')
export const createTeamUser = create(CreateTeamUser, 'branch')
export const createTeamBranch = create(CreateTeamBranch)
export const addExistingCheckpoints = create(AddExistingCheckpoints, 'checkpoints')
export const addExistingEquipmentClassifications = create(AddExistingEquipmentClassifications, 'classifications')
export const quickChecklistClassification = create(QuickChecklistClassification)
export const importEquipment = create(ImportEquipment, 'location')
export const selectChecklists = create(SelectChecklists, 'classification', 'checklists')
export const quickChecklistTemplate = create(QuickChecklistTemplate)
export const uploadAttachment = create(UploadAttachment, 'attachableType', 'attachableId')
export const updateAttachment = create(UpdateAttachment, 'attachment')
export const changeScheduleDate = create(ChangeScheduleDate, 'schedule')
export const transferLocationWorkOrders = create(TransferLocationWorkOrders, 'location')