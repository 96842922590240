export default class PeachPayments {
    constructor(payload = {
        host: '',
        return_url: '',
        checkout: {}
    }) {
        this.checkoutId = payload.checkout.id
        this.host = payload.host
        this.returnUrl = payload.return_url
    }

    getScript() {
        return document.getElementById('peach-script')
    }

    getForm() {
        return document.getElementById('peach-form')
    }

    removeScript() {
        let script = this.getScript()
        if (script) script.remove()
    }

    insertScript() {
        let script = document.createElement('script')
        script.src = `${this.host}/paymentWidgets.js?checkoutId=${this.checkoutId}`
        script.id = 'peach-script'
        document.head.appendChild(script);
    }

    removeForm() {
        let form = this.getForm()
        if (form) form.remove()
    }

    insertForm(toElementId) {
        let form = document.createElement('form')
        form.action = `${this.returnUrl}`
        form.id = 'peach-form'
        form.className = 'paymentWidgets'
        form.setAttribute('data-brands', 'VISA MASTER AMEX')
        document.getElementById(toElementId).appendChild(form);
        setTimeout(() => 200)
    }

    initCard() {
        this.removeScript()
        this.insertScript()
    }

    initForm(toElementId) {
        this.removeForm()
        this.insertForm(toElementId)
    }
}