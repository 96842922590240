<template>
    <modal wide title="New Checklist Classification">
        <form>
            <columns>
                <column>
                    <text-input classes="is-rounded is-medium" required :error="$root.errors.name"
                        :value="checklist_classification.name" @input="updateName"
                        placeholder="Eg. survey, maintenance, commissioning">
                        Name
                    </text-input>
                </column>
            </columns>

            <report-configuration />

            <columns>
                <column>
                    <submit-button class="is-rounded is-medium" :working="working" @submit="createClassification">
                        Save
                    </submit-button>
                </column>
            </columns>
        </form>
    </modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { checklistClassification as backend } from '@/api'
import ReportConfiguration from '../pages/checklist-classification/report-configuration/ReportConfig.vue'

export default {

    components: {
        ReportConfiguration
    },

    data: () => ({
        loading: true,
        working: false,
        classifications: []
    }),

    async beforeCreate() {
        await backend.loadList(({ data }) => {
            this.classifications = data
            this.loading = false
        }, () => this.loading = false)
    },

    beforeDestroy() {
        this.clearChecklistClassification()
    },

    methods: {
        ...mapMutations('checklistClassification', [
            'updateName',
            'clearChecklistClassification'
        ]),
        createClassification() {
            this.working = true
            this.$store.dispatch('checklistClassification/create').then(data => {
                this.$toast.success('Classification created')
                this.$close(data)
                this.working = false
            }).catch(() => this.working = false)
        }
    },

    computed: {
        ...mapGetters('checklistClassification', [
            'checklist_classification'
        ])
    }

}
</script>