<template>
    <modal title="Change Schedule Date">
        <columns>
            <column>
                <date-picker
                    required
                    classes="is-medium is-rounded"
                    v-model="new_date"
                    :error="$root.errors.date"
                    :min-date="minDate"
                    description="Select the new date for this schedule to run on.">
                    Date
                </date-picker>
            </column>
        </columns>

        <template #footer>
            <submit-button class="is-rounded" :working="working" @submit="updateDate">
                Save
            </submit-button>
        </template>
    </modal>
</template>
<script>
import moment from 'moment'
import { workOrderTemplate as backend } from '@/api'

export default {

    props: {
        schedule: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        working: false,
        new_date: ''
    }),

    mounted() {
        this.isPortrait = this.$refs.image.width < this.$refs.image.height
    },

    methods: {
        updateDate() {
            this.working = true
            backend.updateScheduleDate({
                work_order_id: this.schedule.work_order_template_id,
                schedule_id: this.schedule.id,
                date: this.new_date,
            }, () => {
                this.working = false
                this.$toast.success('Schedule has been updated.')
                this.$close(true)
            }, this.working = false)
        }
    },

    computed: {
        minDate() {
            return moment().format('Y-MM-DD')
        }
    }

}
</script>