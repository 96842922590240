<template>
<div>
    <columns>
        <column>
            <h5 class="title is-4">
                Checkpoints <small class="has-text-weight-light">(optional)</small>
            </h5>
            <h6 class="subtitle is-6 has-text-grey">
                Select from existing checkpoints or create new checkpoints to populate this template. There are no limits to how many checkpoints can be placed on a checklist
                but keep in mind that the PDF export size will increase for very large checklists.
            </h6>
        </column>
        <column class="is-4 is-flex is-justify-content-end is-align-items-end">
            <action-button left-icon="plus" class="is-ghost is-small is-rounded" @click="newCheckpoint">Create Checkpoint</action-button>
            <action-button left-icon="search" class="is-warning is-small is-rounded" @click="openCheckpointSearch">Search Checkpoint Library</action-button>
        </column>
    </columns>
    <columns>
        <column>
            <div class="index-rows">
                <draggable handle=".handle" v-model='checklistCheckpoints'>
                    <div class="box is-marginless" v-for="(checkpoint, index) in checklistCheckpoints" :key="checkpoint.id">
                        <columns class="is-marginless is-paddingless">
                            <column class="is-narrow">
                                <icon class="handle mr-2 has-text-grey" icon="grip-vertical"/>
                            </column>
                            <column class="is-marginless is-paddingless">
                                <p class="has-text-weight-bold">{{ index + 1 }}. {{ checkpoint.instruction }}</p>
                                <p class="has-text-grey has-text-weight-light"><em>{{ checkpoint.additional_information }}</em></p>
                            </column>
                            <column class="is-narrow is-flex is-align-items-center is-marginless is-paddingless">
                                <action-button
                                    class="is-small is-rounded is-danger"
                                    @click="removeCheckpoint(checkpoint)">
                                    <icon icon="trash"/>
                                </action-button>
                            </column>
                        </columns>
                    </div>
                </draggable>
                <p class="has-text-centered mt-2 mb-2" v-if="!checklist.checkpoints.length">There are currently no checkpoints assigned to this template.</p>
            </div>
        </column>
    </columns>
</div>
</template>
<script>
import { quickCheckpoint, addExistingCheckpoints } from '@/modals'
import { mapGetters } from 'vuex'
import { checkpoint as backend } from '@/api'
import Draggable from 'vuedraggable'

export default {

    components: {
        Draggable
    },

    data: () => ({
        results: [],
        checkpoints: []
    }),

    beforeCreate() {
        backend.searchForCheckpoints('', ({data}) => {
            this.checkpoints = data
        }, () => {})
    },

    methods: {
        openCheckpointSearch() {
            addExistingCheckpoints(this.checkpoints)
        },
        newCheckpoint() {
            quickCheckpoint()
        },
        removeCheckpoint(checkpoint) {
            this.$store.commit('checklist/removeCheckpoint', checkpoint)
        },
    },

    computed: {
        ...mapGetters('checklist', [
            'checklist'
        ]),
        checklistCheckpoints: {
            get() {
                return this.$store.getters['checklist/checklist'].checkpoints
            },
            set(value) {
                this.$store.commit('checklist/updateCheckpoints', value)
            }
        }
    }

}
</script>