<template>
<modal title="Import Equipment">
    <columns>
        <column>
            <p class="has-text-weight-bold has-text-info">Step 1. Download The Import Template</p>
            <hr>
            <p>Select the type of equipment you would like to import. You can then download the xlsx template file and begin populating the template.</p>
            <p class="has-text-danger mb-3">
                <span class="has-text-weight-bold">NOTE: </span> Do not alter the file name or any of the column names or column positions.
            </p>
            <data-selector
                classes="is-rounded has-addons"
                required
                :items="equipment_classification_list"
                v-model="classification_uuid"
                value-key="uuid"
                label-key="name">
                <template #right>
                    <action-button 
                        :working="downloadingTemplate"
                        @click="downloadtemplate"
                        class="is-rounded"
                        left-icon="download">
                        Download
                    </action-button>
                </template>
            </data-selector>
        </column>
    </columns>
    <columns>
        <column>
            <p class="has-text-weight-bold has-text-info">Step 2. Populate and Upload</p>
            <hr>
            <p>Upload the file below once the xlsx template has been populated with your equipment list.</p>
        </column>
    </columns>
    <columns>
        <column>
            <file-input
                @select="setFile"
                classes="is-rounded">Upload Populated Template</file-input>

            <action-button 
                :working="uploading"
                @click="uploadEquipment"
                class="is-rounded"
                left-icon="upload">
                Upload Equipment
            </action-button>    
        </column>
    </columns>
</modal>    
</template>
<script>
import { mapGetters } from 'vuex'
import download from 'downloadjs'
import { common as backend } from '@/api'
import { location as locationBackend } from '@/api'

export default {

    props: {
        location: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        downloadingTemplate: false,
        uploading: false,
        classification_uuid: '',
        file: ''
    }),

    async beforeCreate() {
        await this.$store.dispatch('equipmentClassification/loadList')
    },

    methods: {
        downloadtemplate() {
            if(!this.classification_uuid) {
                this.$toast.warning('Please Select A Classification')
                return;
            }
            this.downloadingTemplate = true
            backend.downloadFile(`/v1/locations/${this.location.uuid}/equipment-template`, (response) => {
                download(
                    response.data, 
                    `${this.location.id}_${this.classification_uuid}.xlsx`, 
                    response.data.type
                );
                this.downloadingTemplate = false
            }, () => {
                this.downloadingTemplate = false
            }, {
                classification_uuid: this.classification_uuid
            })
        },
        uploadEquipment() {
            if(!this.file) {
                this.$toast.warning('Please select a file before uploading')
                return;
            }
            this.uploading = true
            locationBackend.uploadEquipmentImport({
                uuid: this.location.uuid,
                file: this.file
            }, () => {
                this.uploading = false
                this.$toast.success('Equipment Successfully Imported.')
                this.$close(false)
            }, error => {
                if(error.response.status === 406) {
                    this.$toast.error(error.response.data.error)
                }
                this.uploading = false
            })
        },
        setFile(file) {
            this.file = file
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification_list'
        ]),
    }

}
</script>