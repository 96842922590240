<template>
<modal title="New Branch">
    <loader v-bind="{ loading }">
            <form>
                <columns>
                    <column>
                        <text-input required classes="is-medium is-rounded" :error="$root.errors.name" :value="branch.name"
                            @input="updateName">
                            Name
                        </text-input>
                    </column>
                </columns>
                <columns>
                    <column>
                        <data-selector required classes="is-medium is-rounded" searchable :items="user_list" value-key="id"
                            label-key="full_name" :error="$root.errors.branch_manager_id" :value="branch.branch_manager_id"
                            @input="setBranchManager">
                            Branch Manager
                        </data-selector>
                    </column>
                </columns>
                <columns>
                    <column>
                        <data-selector classes="is-medium is-rounded" multiple searchable :items="user_list" value-key="id"
                            label-key="full_name" :error="$root.errors.users" :value="branch.users" @input="updateUsers">
                            Staff
                        </data-selector>
                    </column>
                </columns>
            </form>
        </loader>

        <template #footer>
             <columns>
                <column>
                    <action-button @click="$close(false)" class="is-ghost has-text-danger">
                        Cancel
                    </action-button>
                    <submit-button class="is-rounded" :working="creating" @submit="createBranch">
                        Create branch
                    </submit-button>
                </column>
            </columns>
        </template>
</modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

    data: () => ({
        loading: true,
        creating: false,
    }),

    async beforeCreate() {
        await this.$store.dispatch('user/loadList')
        this.loading = false
    },

    beforeDestroy() {
        this.clearBranch()
    },

    methods: {
        ...mapMutations('branch', [
            'updateName',
            'clearBranch',
            'updateUsers',
            'updateBranchManager'
        ]),
        setBranchManager(userId) {
            if (this.branch.users.indexOf(userId) === -1) {
                this.updateUsers(this.branch.users.push(this.user_list.find(user => user.id === userId)))
            }
            this.updateBranchManager(userId)
        },
        createBranch() {
            this.creating = true
            this.$store.dispatch('branch/create').then((branch) => {
                this.$toast.success('Branch successfully created')
                this.creating = false
                this.$close(branch)
            }).catch(() => {
                this.creating = false
            })
        }
    },

    computed: {
        ...mapGetters('branch', [
            'branch'
        ]),
        ...mapGetters('user', [
            'user_list'
        ])
    }

}
</script>