<template>
    <div>
        <columns>
            <column>
                <h5 class="title is-4">
                    Properties <small class="has-text-weight-light">(optional)</small>
                </h5>    
                <h6 class="subtitle is-6 has-text-grey">
                    Properties describe traits about equipment. 
                    For example you may need to capture the model, colour or serial number of a particular piece of equipment.
                </h6>
            </column>
        </columns>
        <columns>
            <column>
                <div class="box"
                    :class="{
                        'is-flex is-align-items-center is-justify-content-center': !isCapturingMeta
                    }">
                    <action-button v-if="!isCapturingMeta" @click="capturing_meta = true" class="is-outlined is-small mt-3 mb-3 is-rounded" left-icon="sitemap" type="fas">
                        Start Capturing Properties
                    </action-button>
                    <div v-if="isCapturingMeta">
                        <p class="mb-3">To add a new property complete the fields below.</p>
                        <form>
                            <columns>
                                <column>
                                    <text-input
                                        v-model="property.name"
                                        classes="is-rounded"
                                        required
                                        placeholder="Eg. Serial Number">
                                        Property Name
                                    </text-input>
                                </column>
                                <column>
                                    <text-input
                                        v-model="property.description"
                                        classes="is-rounded"
                                        placeholder="Eg. Record the serial number of the device.">
                                        Description <small class="has-text-weight-light">(optional)</small>
                                    </text-input>
                                </column>
                                <!-- <column>
                                    <select-input
                                        v-model="property.type"
                                        classes="is-rounded"
                                        :items="metadata_types"
                                        required>
                                        Type
                                    </select-input>
                                </column> -->
                                <column class="is-narrow is-flex is-align-items-end">
                                    <action-button 
                                        @click="addMetadataProperty"
                                        class="is-rounded"
                                        left-icon="plus">
                                        Add
                                    </action-button>
                                </column>
                            </columns>
                        </form>

                        <div class="mt-4">
                            <columns v-for="(property, index) in equipment_classification.meta" :key="index">
                                <column>
                                    <p class="is-block">{{ property.name }}</p>
                                    <p class="is-block has-text-grey">{{ property.description }}</p>
                                </column>
                                <!-- <column class="is-2">
                                    {{ property.type }}
                                </column> -->
                                <column class="is-narrow">
                                    <action-button 
                                        class="is-small is-rounded"
                                        @click="removeProperty(property)">Remove</action-button>
                                </column>
                            </columns>
                        </div>
                    </div>
                </div>
            </column>
        </columns>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    
    data: () => ({
        property: {
            name: '',
            description: '',
            type: 'text'
        },
        capturing_meta: false,
        metadata_types: [
            { label: 'Text', value: 'text' },
            { label: 'Numeric', value: 'number' },
            { label: 'Date Picker', value: 'date' },
        ]
    }),

    methods: {
        addMetadataProperty() {
            if(!this.property.name.length) {
                return this.$toast.error('The property name field is required')
            }
            if(!this.property.type.length) {
                return this.$toast.error('Please select a property type')
            }

            if(this.propertyAlreadyExistis(this.property.name)) {
                return this.$toast.error('A property with name already exists')
            }

            this.$store.commit('equipmentClassification/addMetaProperty', this.property)
            this.property = {
                name: '',
                description: '',
                type: 'text'
            }
        },
        removeProperty(property) {
            this.$store.commit('equipmentClassification/removeMetaProperty', property)
        },
        propertyAlreadyExistis(name) {
            return this.equipment_classification.meta.filter(property => property.name.toLowerCase() === name.toLowerCase()).length > 0
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification'
        ]),
        isCapturingMeta() {
            return this.equipment_classification.meta.length || this.capturing_meta
        }
    }

}
</script>